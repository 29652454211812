import { ButtonHTMLAttributes, ClassAttributes } from "react";
import { Interpolation, Theme, css, useTheme } from "@emotion/react";

type TabProps = ClassAttributes<HTMLButtonElement> &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    css?: Interpolation<Theme>;
    variant: "filled" | "line";
    interaction?: "hover" | "select";
    active?: boolean;
  };

export const Tab = ({
  variant,
  interaction,
  active,
  children,
  ...props
}: TabProps) => {
  const theme = useTheme();

  const { enabled, hover, select } = {
    filled: {
      enabled: {
        color: theme.palette.text.placeholder,
        backgroundColor: theme.palette.grey["a100"],
        borderColor: "transparent",
      },
      hover: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey["a100"],
        borderColor: "transparent",
      },
      select: {
        color: theme.palette.common.white.main,
        backgroundColor: theme.palette.secondary.main,
        borderColor: "transparent",
      },
    },
    line: {
      enabled: {
        color: theme.palette.text.placeholder,
        backgroundColor: theme.palette.common.white.main,
        borderColor: theme.palette.grey["200"],
      },
      hover: {
        color: theme.palette.text.placeholder,
        backgroundColor: theme.palette.common.white.main,
        borderColor: theme.palette.grey["200"],
      },
      select: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.common.white.main,
        borderColor: theme.palette.secondary.main,
      },
    },
  }[variant];

  return (
    <button
      css={css`
        position: relative;
        padding: 6px 12px;
        border-radius: ${theme.shape.radius.medium}px;
        background-color: ${enabled.backgroundColor};
        color: ${enabled.color};
        ${theme.typography.body2};
        cursor: pointer;

        &:hover,
        &[data-interaction="hover"] {
          background-color: ${hover.backgroundColor};
          color: ${hover.color};
        }

        &[data-interaction="select"] {
          background-color: ${select.backgroundColor};
          color: ${select.color};
        }

        ${variant === "line" &&
        css`
          border-radius: 0;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1.5px;
            box-shadow: inset 0px -1.5px 0px ${theme.palette.grey[200]};
          }

          &[data-interaction="select"]::after {
            box-shadow: inset 0px -1.5px 0px currentColor;
          }
        `}
      `}
      data-interaction={active ? "select" : interaction}
      {...props}
    >
      {children}
    </button>
  );
};
