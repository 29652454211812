import { ComponentProps, useMemo } from "react";
import { useRouter } from "next/router";
import { Interpolation, Theme, css } from "@emotion/react";
import { Empty } from "components/core/Empty";
import { HorizontalPicker } from "components/core/HorizontalPicker";
import { ContentGridMixContentQuery } from "components/domain/contents/ContentGridMixContentQuery";
import { ContentGridQuery } from "components/domain/contents/ContentGridQuery";
import { SectionScaffold } from "components/domain/contents/sections/SectionScaffold";
import { fireEvent } from "lib/ga";
import { theme } from "styles/theme";
import { zIndex } from "styles/zIndex";
import { LIMIT } from "utils/buildQueryVariables";
import {
  MIX_CONTENT_TAG_ENUM_KOREAN,
  ORDERED_MIX_CONTENT_TAG_ENUM,
} from "utils/enum";

export const RecentSection = ({
  contentSection,
  variables,
  ...props
}: Omit<
  ComponentProps<
    typeof ContentGridQuery<MixContentQuery, MixContentQueryVariables>
  >,
  "useQuery" | "extractor" | "updateQuery"
> & { css?: Interpolation<Theme> }) => {
  const router = useRouter();

  const tag = useMemo(
    () =>
      ORDERED_MIX_CONTENT_TAG_ENUM.find((tag) => tag === router.query.tag) ??
      null,
    [router],
  );

  return (
    <SectionScaffold {...props}>
      <SectionScaffold.Header>
        <SectionScaffold.Title>
          <span>최신 콘텐츠</span>
          {tag !== null && (
            <span
              css={css`
                margin-left: 12px;
                color: ${theme.palette.text.placeholder};

                @media (max-width: 1024px) {
                  display: none;
                }
              `}
            >
              #{MIX_CONTENT_TAG_ENUM_KOREAN[tag]}
            </span>
          )}
        </SectionScaffold.Title>
      </SectionScaffold.Header>
      <SectionScaffold.Contents>
        <HorizontalPicker<Mix_Content_Tag_Enum_Enum | null>
          css={css`
            display: none;

            @media (max-width: 1024px) {
              position: sticky;
              top: calc(var(--header-height) + 1px);
              background: ${theme.palette.common.white.main};
              display: block;
              z-index: ${zIndex.categoryPicker};
              padding-bottom: ${theme.shape.radius.medium}px;
              margin-bottom: 16px;
            }
          `}
          items={[
            { value: null, label: "전체" },
            ...ORDERED_MIX_CONTENT_TAG_ENUM.map((value) => ({
              value,
              label: MIX_CONTENT_TAG_ENUM_KOREAN[value],
            })),
          ]}
          value={tag}
          onChange={(value) => {
            if (value) {
              router.push(
                process.env.BUILD_TARGET === "extension"
                  ? `https://mix.day?tag=${value}`
                  : `?tag=${value}`,
              );

              fireEvent({
                event: "content_category_click",
                params: {
                  value,
                },
              });
            } else {
              router.push(
                process.env.BUILD_TARGET === "extension"
                  ? `https://mix.day`
                  : `/`,
              );
            }
          }}
        />
        <ContentGridMixContentQuery
          contentSection={contentSection}
          variables={variables}
          limit={LIMIT.default}
          empty={
            <Empty
              imgurl="/img/content-empty.png"
              mainText="추천할 만한 콘텐츠를 열심히 찾고 있어요."
              subText="조금만 기다려 주세요!"
            />
          }
        />
      </SectionScaffold.Contents>
    </SectionScaffold>
  );
};
