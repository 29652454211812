import { ComponentProps } from "react";
import { ContentGridQuery } from "./ContentGridQuery";
import { useMixContentQuery } from "graphql/generated";
import _ from "lodash";

export const ContentGridMixContentQuery = (
  props: Omit<
    ComponentProps<
      typeof ContentGridQuery<MixContentQuery, MixContentQueryVariables>
    >,
    "useQuery" | "extractor" | "updateQuery"
  >,
) => {
  return (
    <ContentGridQuery
      useQuery={useMixContentQuery}
      extractor={(result) => result.mix_content}
      updateQuery={(previousQueryResult, { fetchMoreResult }) => {
        return {
          mix_content: _.uniqBy(
            [
              ...previousQueryResult.mix_content,
              ...fetchMoreResult.mix_content,
            ],
            (item) => item.id,
          ),
        };
      }}
      {...props}
    />
  );
};
