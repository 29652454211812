import { HTMLAttributes, useEffect, useRef } from "react";
import { Tab } from "./tabs/Tab";
import { Interpolation, Theme, css } from "@emotion/react";

export function HorizontalPicker<T>({
  css: cssProp,
  items,
  value,
  onChange,
  ...props
}: Omit<HTMLAttributes<HTMLDivElement>, "onChange"> & {
  css?: Interpolation<Theme>;
  items: Array<{ label: string; value: T }>;
  value: T;
  onChange: (value: T) => void;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const children = Array.from(ref.current.children);

      const index = items.findIndex((item) => item.value === value);

      if (index === -1) {
        return;
      }

      const target = children[index];

      const isMobile = ref.current.clientWidth <= 768;

      if (target instanceof HTMLElement) {
        ref.current.scrollTo({
          left: target.offsetLeft - (isMobile ? 16 : 24),
          behavior: "smooth",
        });
      }
    }
  }, [value]);

  return (
    <div
      css={css`
        position: relative;
        margin: 0 calc(-1 * var(--page-padding));
      `}
      {...props}
    >
      <div
        ref={ref}
        css={css`
          display: flex;
          align-items: center;
          padding: 0 var(--page-padding);
          overflow: auto;
        `}
      >
        {items.map((item) => (
          <Tab
            css={css`
              flex-shrink: 0;
              :not(:last-of-type) {
                margin-right: 4px;
              }
            `}
            key={item.label}
            variant="filled"
            active={item.value === value}
            onClick={() => {
              onChange(item.value);
            }}
          >
            {item.label}
          </Tab>
        ))}
      </div>
    </div>
  );
}
