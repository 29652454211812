import Image from "next/image";
import { Text } from "./Text";
import { Interpolation, Theme, css, useTheme } from "@emotion/react";

export const Empty = ({
  imgurl,
  mainText,
  subText,
  ...props
}: {
  css?: Interpolation<Theme>;
  imgurl: string;
  mainText: string | React.ReactNode;
  subText?: string | React.ReactNode;
}) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 17vh;

        @media (max-width: 768px) {
          padding-top: 13vh;
        }
      `}
      {...props}
    >
      <div
        css={css`
          position: relative;
          width: 140px;
          height: 140px;
          margin-bottom: 36px;

          @media (max-width: 768px) {
            width: 80px;
            height: 80px;
            margin-bottom: 24px;
          }
        `}
      >
        <Image alt="empty" src={imgurl} fill />
      </div>
      {typeof mainText === "string" ? (
        <Text
          css={css`
            font-weight: 500 !important;
            word-break: keep-all;
            text-align: center;
            margin-bottom: 12px;

            @media (max-width: 768px) {
              ${theme.typography.h4};
              margin-bottom: 8px;
            }
          `}
          variant="h3"
        >
          {mainText}
        </Text>
      ) : (
        mainText
      )}
      {subText &&
        (typeof subText === "string" ? (
          <Text
            css={css`
              text-align: center;
              white-space: pre-wrap;

              @media (max-width: 768px) {
                ${theme.typography.body3};
              }
            `}
            variant="body1"
            color="text.disabled"
          >
            {subText}
          </Text>
        ) : (
          subText
        ))}
    </div>
  );
};
