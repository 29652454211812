import { useEffect, useMemo, useState } from "react";
import { GetServerSideProps } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Seperator } from "components/core/Seperator";
import { MixedSection } from "components/domain/contents/sections/MixedSection";
import { OriginalSection } from "components/domain/contents/sections/OriginalSection";
import { RecentSection } from "components/domain/contents/sections/RecentSection";
import { MixContentDocument, SessionDocument } from "graphql/generated";
import { fireEvent } from "lib/ga";
import { LIMIT, buildQueryVariables } from "utils/buildQueryVariables";
import { getClient } from "utils/client";
import { ORDERED_MIX_CONTENT_TAG_ENUM } from "utils/enum";

const Container = styled.div`
  --section-desktop-gap: 56px;
  --section-tablet-gap: 48px;
  --section-mobile-gap: 32px;

  display: flex;
  flex-direction: column;
`;

const SectionBox = styled.div`
  margin-bottom: var(--section-desktop-gap);

  @media (max-width: 1024px) {
    margin-bottom: var(--section-tablet-gap);
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const SectionSeperator = styled(Seperator)`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    width: auto;
    margin: var(--section-mobile-gap) calc(-1 * var(--page-padding));
  }
`;

const Page = () => {
  const router = useRouter();

  const tag = useMemo(
    () =>
      ORDERED_MIX_CONTENT_TAG_ENUM.find((tag) => tag === router.query.tag) ??
      null,
    [router],
  );

  const [touched, setTouched] = useState<
    Record<IndexPageContentSection, boolean>
  >({
    original: false,
    recent: false,
    mixed: false,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          const sections = ["original", "recent", "mixed"];
          const targetId = entry.target.getAttribute("data-observer-id");

          if (typeof targetId === "string" && sections.includes(targetId)) {
            const section = targetId as IndexPageContentSection;

            if (entry.isIntersecting && !touched[section]) {
              fireEvent({
                event: "scroll_depth",
                params: {
                  scrolled_section: section,
                },
              });

              setTouched((prev) => ({ ...prev, [section]: true }));
            }
          } else {
            throw new Error("targetId is not a section!");
          }
        }
      },
      {
        root: document.querySelector("index-page-container"),
        rootMargin: "0% 0px -100% 0px",
      },
    );

    const elements = document.querySelectorAll("[data-observer-id]");
    elements.forEach((el) => observer.observe(el));

    return () => elements.forEach((el) => observer.unobserve(el));
  }, [touched]);

  return (
    <>
      <Head>
        <link rel="canonical" href="https://mix.day/" />
      </Head>
      <Container id="index-page-container">
        {tag === null && (
          <>
            {/* OriginalSection : table, mobile */}
            <SectionBox
              css={css`
                display: none;

                @media (max-width: 1024px) {
                  display: block;
                }
              `}
            >
              <OriginalSection
                data-observer-id="original"
                contentSection="original-main"
                variables={buildQueryVariables(
                  "original",
                  null,
                  LIMIT.original,
                )}
              />
            </SectionBox>
          </>
        )}
        {/* RecentSection : tablet, mobile */}
        <SectionBox
          css={css`
            display: none;

            @media (max-width: 1024px) {
              display: block;
            }
          `}
        >
          {tag === null && <SectionSeperator />}
          <RecentSection
            data-observer-id="recent"
            contentSection="recent"
            variables={buildQueryVariables("recent", tag, LIMIT.default)}
          />
        </SectionBox>
        {/* MixedSection : desktop only */}
        <SectionBox
          css={css`
            display: block;

            @media (max-width: 1024px) {
              display: none;
            }
          `}
        >
          <MixedSection
            data-observer-id="mixed"
            contentSection="mixed"
            variables={buildQueryVariables("recent", tag, LIMIT.default)}
          />
        </SectionBox>
      </Container>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const client = getClient({
    headers: {
      cookie: ctx.req.headers.cookie,
    },
    ctx,
  });

  const tag =
    ORDERED_MIX_CONTENT_TAG_ENUM.find((tag) => tag === ctx.query?.tag) ?? null;

  try {
    await client.query<MixContentQuery, MixContentQueryVariables>({
      query: MixContentDocument,
      variables: buildQueryVariables("original", null, LIMIT.original),
    });

    await client.query<MixContentQuery, MixContentQueryVariables>({
      query: MixContentDocument,
      variables: buildQueryVariables("recent", tag, LIMIT.default),
    });

    await client.query<SessionQuery, SessionQueryVariables>({
      query: SessionDocument,
    });

    return {
      props: {
        __APOLLO_CACHE_DATA__: client.extract(),
      },
    };
  } catch (error) {
    return {
      props: {
        __APOLLO_CACHE_DATA__: client.extract(),
      },
    };
  }
};

export default Page;
